@font-face {
  font-family: 'LargeText';
  src: url('./components/assets/fonts/champagne_limousines/Champagne\ &\ Limousines.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LargeText';
  src: url('./components/assets/fonts/champagne_limousines/Champagne\ &\ Limousines\ Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Titulo';
  src: url('./components/assets/fonts/abhaya-libre/AbhayaLibre-ExtraBold.ttf') format('truetype');
  font-weight: extrabold;
  font-style: normal;
}

@font-face {
  font-family: 'Titulo';
  src: url('./components/assets/fonts/abhaya-libre/AbhayaLibre-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Chamos';
  src: url('./components/assets/fonts/a_attack_graffiti/aAttackGraffiti.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Chamos';
  src: url('./components/assets/fonts/a_attack_graffiti/aAttackGraffiti.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.no-scroll{
  overflow: hidden;
}

body {
  background-color: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  color-scheme: dark;
}

textarea{
  resize: none;
}

::-webkit-scrollbar {
  display: none;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
