.button {
    position: relative;
    display: inline-block;
    overflow: hidden;
    border-radius: 999px;
}

.button span {
    position: absolute;
    display: block;
    border-radius: 999px;
}

.button span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #ffd900);
    border-radius: 999px; 
}

.button:hover span:nth-child(1) {
    left: 100%;
    transition: 600ms;
}

.button span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #ffd900);
    border-radius: 999px; 
}

.button:hover span:nth-child(3) {
    right: 100%;
    transition: 1s;
    transition-delay: 250ms;
}

.button span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #ffd900);
    border-radius: 999px; 
}

.button:hover span:nth-child(2) {
    top: 100%;
    transition: 1s;
    transition-delay: 100ms;
}

.button span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #ffd900);
    border-radius: 999px; 
}

.button:hover span:nth-child(4) {
    bottom: 100%;
    transition: 1s;
    transition-delay: 450ms;
}

/* --------------------------------------TITULO --------------------------------------------*/

.titulo {
    text-transform: uppercase;
    text-align: center;
    font-family: 'titulo', sans-serif;
    letter-spacing: 0.2rem;
  }
  
  .titulo {
    color: white;
    animation: blink 2s infinite alternate;
  }
  
  @keyframes blink {
    0% {
        opacity: 0.3;
      }
    100% {
      opacity: 1;
      text-shadow:
      #FFE500 1px 0px 10px, 
      #FFE500 1px 0px 20px,
      #FFE500 1px 0px 22px,
      #FFE500 1px 0px 40px,
      #FFE500 1px 0px 60px,
      #FFE500 1px 0px 80px;
    }
  }
  
  .circle {
    border-color: var(--border-color);
    background-color: var(--background-color);
  }

  .circle:hover {
    animation: circle 2s infinite alternate;
  }
  
  .circle2 {
    animation: selected 2s infinite alternate;
  }

  @keyframes selected {
    0%{
      border: solid 2px var(--shadow-color);
    }
    100%{
      border: solid 2px antiquewhite;
      box-shadow:
        inset 0 0 0 var(--shadow-color),
        0 0 10px var(--shadow-color),
        0 0 20px var(--shadow-color),
        0 0 30px var(--shadow-color),
        0 0 40px var(--shadow-color); 
      scale: 1.05;
    }
  }

  @keyframes circle {
    0%{
      border: solid 2px var(--shadow-color);
    }
    100%{
      border: solid 2px antiquewhite;
      box-shadow:
        inset 0 0 0 var(--shadow-color),
        0 0 10px var(--shadow-color),
        0 0 20px var(--shadow-color),
        0 0 30px var(--shadow-color),
        0 0 40px var(--shadow-color); 
    }
  }


 