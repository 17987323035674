@keyframes flame {
  0% {
    text-shadow: 
      0 0 10px rgba(0, 255, 255, 0.5), 
      0 0 20px rgba(0, 255, 255, 0.7), 
      0 0 30px rgba(0, 0, 255, 0.6),
      0 0 40px rgba(0, 0, 255, 0.9);
  }
  25% {
    text-shadow: 
      0 0 15px rgba(0, 255, 255, 0.6), 
      0 0 30px rgba(0, 255, 255, 0.9), 
      0 0 50px rgba(0, 0, 255, 0.7),
      0 0 60px rgba(0, 0, 255, 1);
  }
  50% {
    text-shadow: 
      0 0 20px rgba(0, 255, 255, 0.7), 
      0 0 40px rgba(0, 255, 255, 1), 
      0 0 60px rgba(0, 0, 255, 0.8),
      0 0 80px rgba(0, 0, 255, 1);
  }
  75% {
    text-shadow: 
      0 0 25px rgba(0, 255, 255, 0.5), 
      0 0 50px rgba(0, 255, 255, 0.7), 
      0 0 70px rgba(0, 0, 255, 0.6),
      0 0 90px rgba(0, 0, 255, 1);
  }
  100% {
    text-shadow: 
      0 0 10px rgba(0, 255, 255, 0.5), 
      0 0 20px rgba(0, 255, 255, 0.7), 
      0 0 30px rgba(0, 0, 255, 0.6),
      0 0 40px rgba(0, 0, 255, 0.9);
  }
}

.title {
  font-family: 'Arial', sans-serif;
  animation: flame 1.5s infinite;
}

  .circle {
    border-color: var(--border-color);
    background-color: var(--background-color);
  }

  .circle:hover {
    animation: circle 2s infinite alternate;
  }
  
  .circle2 {
    animation: selected 2s infinite alternate;
  }

  @keyframes selected {
    0%{
      border: solid 2px var(--shadow-color);
    }
    100%{
      border: solid 2px antiquewhite;
      box-shadow:
        inset 0 0 0 var(--shadow-color),
        0 0 10px var(--shadow-color),
        0 0 20px var(--shadow-color),
        0 0 30px var(--shadow-color),
        0 0 40px var(--shadow-color); 
      scale: 1.05;
    }
  }

  @keyframes circle {
    0%{
      border: solid 2px var(--shadow-color);
    }
    100%{
      border: solid 2px antiquewhite;
      box-shadow:
        inset 0 0 0 var(--shadow-color),
        0 0 10px var(--shadow-color),
        0 0 20px var(--shadow-color),
        0 0 30px var(--shadow-color),
        0 0 40px var(--shadow-color); 
    }
  }