
  .loader {
    position: relative;
    top: 20px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 100px;
    height: 95px;
  }
  
  .loader span {
    display: block;
    background: #ccc;
    width: 2px;
    height: 5%;
    border-radius: 14px;
    margin-right: 5px;
    float: left;
    margin-top: 25%;
  }
  
  .loader span:last-child {
    margin-right: 0px;
  }
  
  .loader span:nth-child(1) {
    animation: load 2.5s 1.4s infinite linear;
  }
  
  .loader span:nth-child(2) {
    animation: load 2.5s 1.2s infinite linear;
  }
  
  .loader span:nth-child(3) {
    animation: load 2.5s 1s infinite linear;
  }
  
  .loader span:nth-child(4) {
    animation: load 2.5s 0.8s infinite linear;
  }
  
  .loader span:nth-child(5) {
    animation: load 2.5s 0.6s infinite linear;
  }
  
  .loader span:nth-child(6) {
    animation: load 2.5s 0.4s infinite linear;
  }
  
  .loader span:nth-child(7) {
    animation: load 2.5s 0.2s infinite linear;
  }
  
  .loader span:nth-child(8) {
    animation: load 2.5s 0s infinite linear;
  }
  
  .loader span:nth-child(9) {
    animation: load 2.5s 0.2s infinite linear;
  }
  
  .loader span:nth-child(10) {
    animation: load 2.5s 0.4s infinite linear;
  }
  
  .loader span:nth-child(11) {
    animation: load 2.5s 0.6s infinite linear;
  }
  
  .loader span:nth-child(12) {
    animation: load 2.5s 0.8s infinite linear;
  }
  
  .loader span:nth-child(13) {
    animation: load 2.5s 1s infinite linear;
  }
  
  .loader span:nth-child(14) {
    animation: load 2.5s 1.2s infinite linear;
  }
  
  .loader span:nth-child(15) {
    animation: load 2.5s 1.4s infinite linear;
  }
  
  @keyframes load {
    50% {
      background: #444;
      height: 100%;
      margin-top: 0%;
    }
    100% {
      background: #ffffff;
      height: 10%;
      margin-top: 25%;
    }
  }

  .card {
    position: relative;
    border: 2px solid transparent;
    transition: border 0.5s ease;
}

.card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid rgb(255, 251, 0);
    transform: scale(0);
    transition: transform 0.5s ease;
}

.card:hover::before {
    transform: scale(1);
}

.card:hover {
    border-color: rgb(255, 251, 0);
}
