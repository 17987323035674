.animation-container {
    position: absolute;
    top:-100px;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 50;
}

.leaf {
    position: absolute;
    width: 100px;
    height: 100px; 
    background-size: contain;
    background-repeat: no-repeat;
    animation: fall 25s infinite;
}

.leaf1 {
    background-image: url('../assets/Imagenes/leafAbout.png');
    left: 10%;
    animation-delay: 0.5s;
}

.leaf2 {
    background-image: url('../assets/Imagenes/leafAbout.png');
    left: 30%;
    animation-delay: 2.5s;
}

.leaf3 {
    background-image: url('../assets/Imagenes/leafAbout.png');
    left: 50%;
    animation-delay: 4s;
}

.leaf4 {
    background-image: url('../assets/Imagenes/leafAbout.png');
    left: 70%;
    animation-delay: 6s;
}

.leaf5 {
    background-image: url('../assets/Imagenes/leafAbout.png');
    left: 90%;
    animation-delay: 8s;
}

.leaf6 {
    background-image: url('../assets/Imagenes/leafAbout.png');
    left: 10%;
    animation-delay: 5s;
}

.leaf7 {
    background-image: url('../assets/Imagenes/leafAbout.png');
    left: 40%;
    animation-delay: 3s;
}

.leaf8 {
    background-image: url('../assets/Imagenes/leafAbout.png');
    left: 50%;
    animation-delay: 0s;
}

@keyframes fall {
    0% {
        transform: translateY(-100px) translateX(500px) rotate(0deg);
        opacity: 1;
    }
    100% {
        transform: translateY(3400%) translateX(-1500px) rotate(360deg);
        opacity: .1;
    }
}


@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

.animate-fade-out {
    animation: fadeOut 3s forwards;
}

  .flecha {
    color: white;
    animation: blink-2 1s infinite alternate;
  }

  .redes {
    animation: redesBlink 1s infinite alternate;
  }

  @keyframes blink-2 {
    0% {
        opacity: 0.1;
        filter: drop-shadow(0px 0px 5px #ffffff);
      }
    100% {
      opacity: 1;
      scale: 1.1;
      filter: drop-shadow(0px 10px 8px #ffffff);
    }
  }
  @keyframes redesBlink {
    0% {
        opacity: 0.1;
      }
    100% {
      opacity: 1;
      scale: 1.1;
    }
  }

  .vapor span {
    animation: animation 7s linear infinite;
    filter: blur(5px);
    animation-delay: calc(var(--i)*.5s);
  }

  @keyframes animation {
    0%{
        transform: translateY(-300%) scale(5);
    }
    50%{
        transform: translateY(-750%) scale(3);
        opacity: 1;
    }
    95%{
        opacity: 0;
    }
    100%{
        transform: translateY(-250px) scale(1);
        opacity: 0;
    }
  }

